import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from "../components/TeacherDrawer.jsx";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/userSlice.js';
import TeacherDashboard from "../components/TeacherDashboard.jsx"

const AppContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 15px;
  overflow: hidden; 
 font-family: "Poppins", sans-serif;
`;

const ContentContainer = styled.div`
  transition: margin-left 0.3s;
  padding: 20px;
  width: 100%;
  height: 100vh; /* İçeriğin yüksekliği */
  overflow-y: auto; /* İçeriğin kaydırılmasını sağlar */
`;

const Dashboard = () => {
  const [selectedItem, setSelectedItem] = useState('anasayfa');
  const [drawerOpen, setDrawerOpen] = useState(true);


  const dispatch = useDispatch(); // Initialize dispatch
  const navigate = useNavigate(); // Initialize navigate



  const renderComponent = () => {
    switch (selectedItem) {
      case 'anasayfa':
        return <TeacherDashboard/>;
    }
  };

  return (
    <AppContainer>
      <Sidebar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
      <ContentContainer drawerOpen={drawerOpen}>
        {renderComponent()}
      </ContentContainer>
    </AppContainer>
  );
}

export default Dashboard;
