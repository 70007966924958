import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiUser, FiLock } from 'react-icons/fi';
import { TbCircleChevronDown } from "react-icons/tb";
import { MdOutlineMailOutline } from "react-icons/md";
import { LuMailPlus } from "react-icons/lu";
import { AiOutlineNumber } from "react-icons/ai";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { fetchUserInfo } from '../redux/userSlice';
import { useDispatch } from 'react-redux';

const LoginBox = styled.div`
  padding: 5em 2.5em 4em 2.5em;
  color: #6d6d6e;
  box-shadow: none;
  margin: auto;
`;



const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 30px;
    color: #5a5a5d;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
`;

const InputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`;

const InputField = styled.input`
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: #4c4a70;
  padding-inline: 20px 50px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #acacbb;
  border-radius: 30px;
  outline: none;

  &:focus ~ label,
  &:valid ~ label {
    position: absolute;
    top: -10px;
    left: 20px;
    font-size: 14px;
    background-color: #4c4a70;
    border-radius: 30px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    padding: 0 10px;
  }
`;

const Label = styled.label`
  position: absolute;
  top: 15px;
  left: 20px;
  transition: 0.2s;
`;

const Icon = styled.i`
  position: absolute;
  top: 18px;
  right: 25px;
  font-size: 20px;
`;

const RememberForgot = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 15px;
`;

const InputSubmit = styled.input`
  width: 100%;
  height: 50px;
  background: #4c4a70;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #4c4a70bd;
  }
`;



const AuthContainer = styled.div`
  display: flex;
  position: relative;
  width: 40%;
  height: 100vh;
  align-items: center;
  background-color: #f7f7fc;
  margin: 0 auto;
  justify-content: center;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 60%;
`;



function LoginStudent() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        phone: "",
        kvkk: "1"
    });
    const navigate = useNavigate();
    const dispatch=useDispatch()
    const handleInputChange = (e) => {
        const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          let url, payload;
          url = 'https://api.robark.com.tr/api/teacher/login';
          payload = {
              email: formData.email,
              password: formData.password,
          }
  
          const response = await axios.post(url, payload, {
              withCredentials: true,
              headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Headers': '*'
              }
          });
          
          console.log("gelen response", response);
          
          if (response.data.token) {
              localStorage.setItem('token', response.data.token);
              if (response.data.token && response.data.status) {
                  dispatch(fetchUserInfo(response.data.token));
              }
              console.log("rrrr", response.data);
              console.log('Token başarıyla kaydedildi.');
          } else {
              console.error('Token alınamadı veya giriş başarısız:', response.data.message);
          }
  
          navigate('/teacher');
      } catch (error) {
          console.error('Giriş başarısız:', error);
          console.log(formData);
      }
  };
  


    return (
        <AuthContainer>

                <LeftPanel >
                <LoginHeader> <span>Giriş Yap</span> </LoginHeader>
                    <InputBox>
                        <InputField
                            type="text"
                            id="user"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <Label htmlFor="user">Email</Label>
                        <Icon>
                            <MdOutlineMailOutline style={{ color: "#8f8e9f" }} />
                        </Icon>
                    </InputBox>
                    <InputBox>
                        <InputField
                            type="password"
                            id="pass"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required
                        />
                        <Label htmlFor="pass">Şifre</Label>
                        <Icon>
                            <FiLock style={{ color: "#8f8e9f" }} />
                        </Icon>
                    </InputBox>
                    <InputBox>
                        <InputSubmit  onClick={handleSubmit} type="submit" value="Giriş Yap" />
                    </InputBox>

                
            </LeftPanel>

         
       
    </AuthContainer >
  );
}

export default LoginStudent;
