import React, { useEffect, useState } from 'react';
import Lessons from '../components/Lessons.jsx'; // Import the Lessons component
import Testler from '../components/Tests.jsx'; // Import the Testler component
import styled from 'styled-components';
import Sidebar from "../components/StudentDrawer.jsx";
import Homeworks from '../components/Homeworks.jsx';
import StudentDashboard from '../components/StudentDashboard.jsx';
import { logout } from '../../../redux/userSlice.js';
import Chatbot from '../components/Chatbot.jsx';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MyProfile from '../components/MyProfile.jsx';
const AppContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Taşmayı engeller */
  font-family: "Poppins", sans-serif;
`;

const SidebarContainer = styled.div`
  width: ${props => (props.isCollapsed ? '100px' : '270px')};
  flex-shrink: 0; /* Sidebar genişliğinin sabit kalmasını sağlar */
  transition: width 0.3s ease;
  background-color: #fcfcfc;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  flex-grow: 1; /* Sidebar dışında kalan tüm alanı kaplar */
  padding: 20px;
  overflow-y: auto; /* Yatay taşmayı engeller */
  box-sizing: border-box;
  transition: margin-left 0.3s, width 0.3s;
`;


const Dashboard = () => {
  const [selectedItem, setSelectedItem] = useState('anasayfa');
  const [drawerOpen, setDrawerOpen] = useState(true);


  const dispatch = useDispatch(); 
  const navigate = useNavigate(); 

  const handleLogout = () => {
    dispatch(logout());
  };


  useEffect(() => {
    if (selectedItem === 'cikis') {
      handleLogout();
    }
  }, [selectedItem, handleLogout]); 


  const renderComponent = () => {
    switch (selectedItem) {
      case 'anasayfa':
        return <StudentDashboard/>;
      case 'iceriklerim':
        return;
      case 'odevler':
        return <Homeworks />;
      case 'testler':
        return <Testler />;
      case 'performans':
        return <Chatbot/>;
      case 'dersler':
        return <Lessons />;
      case 'profilim':
        return <MyProfile />;
      default:
        return;
    }
  };

  return (
    <AppContainer>
      <Sidebar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
      <ContentContainer drawerOpen={drawerOpen}>
        {renderComponent()}
      </ContentContainer>
    </AppContainer>
  );
}

export default Dashboard;