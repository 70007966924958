import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import {  IoLogOutOutline } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";
import { GrDocumentPerformance } from "react-icons/gr";
import { PiChalkboardTeacher } from "react-icons/pi";
import { MdCastForEducation } from "react-icons/md";
import { MdOutlineHomeWork } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BiSolidSchool } from "react-icons/bi";
import { GrDocumentStore } from "react-icons/gr";
import { LuUserCog } from "react-icons/lu";
import { LiaUserCheckSolid } from "react-icons/lia";
import { TbDashboard } from "react-icons/tb";
import {store} from "../../../redux/store.js"


import { Navigate } from "react-router-dom";
import Navbar from './Navbar';
import { useState, useEffect } from 'react';
import axios from 'axios';
import AdminEkle from './AdminEkle';
import Dashboard from './Dashboard';

const drawerWidth = 255;
axios.defaults.withCredentials = true;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingLeft:"10px",
    paddingRight:"10px",
    ...(open && {
      paddingLeft: drawerWidth,
    }),
  }),
  
);

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
);

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    marginTop: "90px",
    borderRight: "none",
    overflowY: 'hidden', // Öncelikle scrollbar'ı gizle
    scrollbarWidth: "thin",
    scrollbarColor: "#d5d5d5 rgb(255, 255, 255)",
    '&:hover': {
      overflowY: 'auto', // Drawer üzerine hover olduğunda scrollbar göster
      '&::-webkit-scrollbar': {},
    },
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: "#4f4f4f",
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  padding: "15px",
  paddingLeft: "50px",
  borderRadius: "10px",
  '&:hover': {
    backgroundColor: " rgba(111, 133, 147, 0.1)",
    color: "#064792",
    '& .StyledListItemText': {
      color: "#064792", // Hover olduğunda yazı rengini mor yap
    },
  },
}));

const StyledListItemContent = styled('div')({
  display: "flex",
  alignItems: "center",
});

const StyledListItemText = styled(Typography)({
  fontSize: "14px",
  fontFamily: "'Poppins', sans-serif",
  marginLeft: "10px",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#454545",
  fontSize: "15px",
  textAlign: "left",
  marginLeft:"5px",
  paddingTop: "20px",
  paddingLeft: "15px",
  borderRight: "none",
  fontWeight: "600",
}));

export default function MyDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [activeComponent, setActiveComponent] = useState('ayarlarim');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'adminekle':
        return <AdminEkle/>;
        case 'anasayfa':
          return <Dashboard/>;
      default:
        return <Dashboard/>;
        
    }
  };


  useEffect(() => {
    const getUserProfile = async () => {
        try {
            const token = localStorage.getItem('auth_token');
            const response = await axios.get('https://api.ogulcanarslan.com.tr/api/user', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setUser(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log("status", error.response.status);
            store.dispatch(logout());
          } 
            console.error('Profile fetch error:', error);
            setMessage('Profil bilgileri alınamadı.');
      
        }
    };

    getUserProfile();
}, []);

  if (!user) {
    return <div>{message || 'Profil bilgileri yükleniyor...'}</div>;
  }

  const logout = async () => {
    try {
        const token = localStorage.getItem('auth_token');
        await axios.get('https://api.ogulcanarslan.com.tr/api/logout', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        localStorage.clear();
        setRedirect(true);
    } catch (error) {
      
        console.error('Profile fetch error:', error);
        setMessage('Çıkış yapılamadı.');

    }
};


  if (redirect) {
    return <Navigate to="/admin/login" replace={true} />
  }
  return (
    <Box sx={{ display: 'flex', }}>
      <CssBaseline />
      <AppBarStyled position="fixed" style={{ backgroundColor: "white", boxShadow: "none" }}>
        <Toolbar >
          <div>
            <Navbar />
          </div>
          <div style={{display:"inline-block"}}>
          <MenuIcon    aria-label={open ? "close drawer" : "open drawer"}
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ marginLeft: 7,width: 'auto' }}
     style={{ color: "#436586", backgroundColor: " rgba(111, 133, 147, 0.1)", cursor: "pointer", padding: "8px", height: "35px", borderRadius: "35%" }} />
     </div>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className='grid'>
        <StyledTypography variant="h6" gutterBottom>
            Anasayfa
          </StyledTypography>
          <List>
            <StyledListItem button onClick={() =>  setActiveComponent('anasayfa')}>
              <StyledListItemContent>
                <TbDashboard style={{ width: "17px", height: "17px", marginRight: "5px" }} />
                <StyledListItemText className="StyledListItemText">Anasayfa</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
          </List>
          <Divider style={{ width: "80%", margin: "0 auto" }} />

          <StyledTypography variant="h6" gutterBottom>
            Admin
          </StyledTypography>
          <List>
            <StyledListItem button onClick={() =>  setActiveComponent('adminekle')}>
              <StyledListItemContent>
                <LiaUserCheckSolid style={{ width: "17px", height: "17px", marginRight: "5px" }} />
                <StyledListItemText className="StyledListItemText">Admin Ekle</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
          </List>
          <Divider style={{ width: "80%", margin: "0 auto" }} />
          <StyledTypography variant="h6" gutterBottom>
            Öğrenci
          </StyledTypography>
          <List>
            <StyledListItem button>
              <StyledListItemContent>
              <LuUserCog style={{ width: "17px", height: "17px", marginRight: "5px" }} />

                <StyledListItemText className="StyledListItemText">Kayıt Yönetimi</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
            <StyledListItem button>
              <StyledListItemContent>
                <MdOutlinePayment style={{ width: "17px", height: "17px", marginRight: "5px" }} />
                <StyledListItemText className="StyledListItemText">Ödeme ve Abonelik</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
            <StyledListItem button>
              <StyledListItemContent>
                <GrDocumentPerformance style={{ width: "17px", height: "17px", marginRight: "5px" }} />
                <StyledListItemText className="StyledListItemText">Performans Takibi</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
          </List>
          <Divider style={{ width: "80%", margin: "0 auto" }} />
          <StyledTypography variant="h6" gutterBottom>
            Öğretmen
          </StyledTypography>
          <List>
            <StyledListItem button>
              <StyledListItemContent>
                <PiChalkboardTeacher style={{ width: "17px", height: "17px", marginRight: "5px" }} />
                <StyledListItemText className="StyledListItemText">Öğretmen Yönetimi</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
            <StyledListItem button>
              <StyledListItemContent>
                <MdCastForEducation style={{ width: "17px", height: "17px", marginRight: "5px" }} />
                <StyledListItemText className="StyledListItemText">Öğrenci İzleme</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
            <StyledListItem button>
              <StyledListItemContent>
              <MdOutlineHomeWork style={{ width: "17px", height: "17px", marginRight: "5px" }} />

                <StyledListItemText className="StyledListItemText">Ödev Yönetimi</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
            <StyledListItem button>
              <StyledListItemContent>
              <IoMdNotificationsOutline style={{ width: "17px", height: "17px", marginRight: "5px" }} />

                <StyledListItemText className="StyledListItemText">Duyuru ve Bildirimler</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
          </List>
          <Divider style={{ width: "80%", margin: "0 auto" }} />
          <StyledTypography variant="h6" gutterBottom>
            Okul
          </StyledTypography>
          <List>
            <StyledListItem button>
              <StyledListItemContent>
              <BiSolidSchool style={{ width: "17px", height: "17px", marginRight: "5px" }} />
                <StyledListItemText className="StyledListItemText">Okul Yönetimi</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
            <StyledListItem button>
              <StyledListItemContent>
              <GrDocumentStore  style={{ width: "17px", height: "17px", marginRight: "5px" }} />

                <StyledListItemText className="StyledListItemText">Toplu Kayıt Yönetimi</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
            <StyledListItem button>
              <StyledListItemContent>
              <MdOutlinePayment style={{ width: "17px", height: "17px", marginRight: "5px" }} />
                <StyledListItemText className="StyledListItemText">Ödeme ve Abonelik</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
          </List>
          <Divider style={{ width: "80%", margin: "0 auto" }} />
          <List style={{marginBottom:"100px"}}>
            <StyledListItem button onClick={logout}>
              <StyledListItemContent>
                <IoLogOutOutline style={{ width: "20px", height: "20px", marginRight: "5px" }} />
                <StyledListItemText className="StyledListItemText">Çıkış Yap</StyledListItemText>
              </StyledListItemContent>
            </StyledListItem>
          </List>
        </div>
      </DrawerStyled>
      <Main open={open} style={{marginTop:"75px",height: "100vh"}}>
        <div >
        {renderComponent()}
      </div>

      </Main>
    </Box>
  );
}
