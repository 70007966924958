import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import styled from 'styled-components'
import avatar from "../../../assets/images/avatar.png"
import gorsel1 from "../../../assets/images/homework.png"
import gorsel2 from "../../../assets/images/time.png"
import gorsel3 from "../../../assets/images/video.png"
import gorsel4 from "../../../assets/images/test.png"
import { useSelector } from 'react-redux';
import { BsPeople } from "react-icons/bs";
import { PiBuildingApartment } from "react-icons/pi";
import { FiPhone } from "react-icons/fi";
import { FaRegAddressCard } from "react-icons/fa6";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { MdOutlineAccessTime } from "react-icons/md";
import { differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';
import { LuMousePointer2 } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";
import axios from 'axios';
import Checkbox from './Checkbox';

// Responsive Styled Components
const ProfileCard = styled.div`
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  width: 100%;
  background-color: #fcfcfc;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }
`;

const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 19px;
  font-family: Poppins;
  color: rgb(53, 53, 53);
  display: inline;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const InfoName = styled.h6`
  font-size: 20px;
  font-family: Rubik;
  color: rgb(110, 110, 110);
  display: flex;
  align-items: center;
  width: 50%;
  gap: 15px;
  font-weight: 400;
  
  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    gap: 10px;
  }
`;

const StyledTitle = styled.h6`
  font-weight: 500;
  font-size: 13px;
  font-family: Poppins;
  color: rgb(163, 163, 163);
  margin-top: 25px;

  @media (max-width: 480px) {
    font-size: 12px;
    margin-top: 15px;
  }
`;

const Containerr = styled(Container)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  
  @media (max-width: 1024px) {
    padding: 10px;
  }
`;

const MainContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  border-radius: 40px;
  padding: 40px;
  width: 75%;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #6d6d6d;
  font-weight: 600;
  font-family: Raleway;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 20px;
  }
`;

const Inf = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const InfoCard = styled.div`
  border-radius: 40px;
  padding: 20px;
  width: 100%;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #4d4d4d;
  font-weight: 400;
  font-family: Rubik;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  
  margin-bottom: -30px;
  @media (max-width: 768px) {
    margin-top: 15px;
    margin-bottom: 0;

  }
`;

const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
   
   
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const MyProfile = () => {
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  console.log(userInfo);
  const [classId, setClassId] = useState("");
  const [branch, setBranch] = useState("");

  const [schoolName, setSchoolName] = useState("");
  const [paymentInfo, setPaymentInfo] = useState("");
  const [packetInfo, setPacketInfo] = useState("");
  const [formattedDates, setFormattedDates] = useState([]);

  const token = useSelector((state) => state.user.token);
  console.log("qqqqqq",token)
  const gradeId = userInfo.student.grade_id;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const response = await axios.get(`https://api.robark.com.tr/api/student/details`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log("dataa", response.data);
        setSchoolName(response.data.school.name);
        setClassId(response.data.school.classes[0].grade_id);
        setBranch(response.data.school.classes[0].class_branch.branch);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("status", error.response.status);
        }
        console.error('Profile fetch error:', error);
      }
    };

    const getPaymentInfo = async () => {
      try {
        const response = await axios.get(`https://api.robark.com.tr/api/student/subscriptions`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const subscriptions = response.data.subscriptions;

        const formattedStartDate = formatDate(subscriptions.start_date);
        const formattedEndDate = formatDate(subscriptions.end_date);

        setFormattedDates([formattedStartDate, formattedEndDate]);

        setPaymentInfo(subscriptions);
        console.log("asdcgvhbjn", subscriptions);
        setPacketInfo(subscriptions.plan.plan_name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("status", error.response.status);
        }
        console.error('Profile fetch error:', error);
      }
    };

    getUserProfile();
    getPaymentInfo();
  }, [token]);

  const currentDate = new Date();

  const daysDifference = differenceInDays(new Date(paymentInfo.end_date), currentDate);
  const monthsDifference = differenceInMonths(new Date(paymentInfo.end_date), currentDate);
  const yearsDifference = differenceInYears(new Date(paymentInfo.end_date), currentDate);

  let statusMessage = '';

  if (daysDifference <= 30) {
    statusMessage = 'Paketinin süresi bitiyor!';
  } else if (monthsDifference > 0 && monthsDifference <= 6) {
    statusMessage = 'Paketini yenilemene çok az kaldı.';
  } else if (monthsDifference > 6 || yearsDifference > 0) {
    statusMessage = 'Paketinin süresini yenilemeye çok zaman var.';
  }

  return (
    <Containerr>
      <ProfileCard>
        <ProfileInfoWrapper>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <StyledTitle>Ad Soyad</StyledTitle>
            <Styledh6>{userInfo.student.first_name} {userInfo.student.last_name}</Styledh6>
          </div>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <StyledTitle>Mail Adresi</StyledTitle>
            <Styledh6>{userInfo.student.email}</Styledh6>
          </div>
        </ProfileInfoWrapper>
        <AvatarWrapper>
          <img
            src={avatar}
            alt="Kullanıcı Avatarı"
            style={{
              width: "auto",
              maxHeight: "200px",
              objectFit: "contain"
            }}
          />
        </AvatarWrapper>
      </ProfileCard>

      <MainContentWrapper>
        <Card>
          <ul style={{ margin: "0", padding: "0", display: "flex", flexDirection: "column", gap: "20px" }}>
            <li style={{ display: "flex", alignItems: "center" }}>
              <InfoName>
                <BsPeople style={{ width: "25px", display: "inline" }} /> Sınıfın
              </InfoName>
              {userInfo.student.type === "institution" ? (
                <Styledh6>{classId} / {branch}</Styledh6>
              ) : (
                <Styledh6>{gradeId}. Sınıf</Styledh6>
              )}
            </li>

            {
              userInfo.student.type === "institution" && (
                <li style={{ display: "flex", alignItems: "center" }}>
                  <InfoName>
                    <PiBuildingApartment style={{ width: "25px", display: "inline" }} /> Okulun
                  </InfoName>
                  <Styledh6>{schoolName}</Styledh6>
                </li>
              )
            }

            <li style={{ display: "flex", alignItems: "center" }}>
              <InfoName><FiPhone style={{ width: "25px", display: "inline" }} /> Telefonun</InfoName>
              <Styledh6>{userInfo.student.phone}</Styledh6>
            </li>
            <li style={{ display: "flex", alignItems: "center" }}>
              <InfoName><HiOutlineMail style={{ width: "25px", display: "inline" }} /> Mail Adresin</InfoName>
              <Styledh6>{userInfo.student.email}</Styledh6>
            </li>
            <li style={{ display: "flex", alignItems: "center" }}>
              <InfoName><FaRegAddressCard style={{ width: "25px", display: "inline" }} /> KVKK</InfoName>
              <Checkbox />
            </li>
            <InfoName style={{ marginTop: "30px",marginBottom:"10px" }}>Eğitim Paketi Bilgilerin</InfoName>
            <li style={{ display: "flex", alignItems: "center" }}>
              <InfoName><MdOutlineOndemandVideo style={{ width: "25px", display: "inline" }} /> Paketin</InfoName>
              <Styledh6>{packetInfo}</Styledh6>
            </li>
            <li style={{ display: "flex", alignItems: "center" }}>
              <InfoName><MdOutlineAccessTime style={{ width: "25px", display: "inline" }} /> Paket Başlangıç ve Bitiş Tarihi</InfoName>
              <Styledh6 >
                <div style={{marginTop:"70px"}}>
                {formattedDates[0]} - {formattedDates[1]}
                <div style={{
                  borderRadius: "20px",
                  backgroundColor: "#f01919",
                  color: "white",
                  fontWeight: "400",
                  fontSize: "15px",
                  padding: "10px 15px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                }}>
                  {statusMessage}
                  <div style={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30px",
                    height: "30px"
                  }}>
                    <LuMousePointer2 style={{ color: "#f01919", width: "20px" }} />
                  </div>
                </div>
                </div>
              </Styledh6>
            </li>
          </ul>
        </Card>
        <Inf>
          {[
            { img: gorsel2, text: "robarkta geçirdiğin süre", value: "463" },
            { img: gorsel3, text: "izlenilen ders videosu", value: "3435" },
            { img: gorsel4, text: "çözdüğün test sayısı", value: "43545" },
            { img: gorsel1, text: "yapılan ödev sayısı", value: "345" }
          ].map((item, index) => (
            <InfoCard key={index}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "space-evenly" }}>
                <img
                  src={item.img}
                  alt={item.text}
                  style={{ width: "70px", maxHeight: "70px", objectFit: "contain" }}
                />
                <span style={{
                  fontFamily: "'Rubik', sans-serif",
                  fontSize: "35px",
                  color: "#3a3a3a",
                  fontWeight: "400"
                }}>
                  {item.value}
                </span>
              </div>
              <span>{item.text}</span>
            </InfoCard>
          ))}
        </Inf>
      </MainContentWrapper>
    </Containerr>
  )
}

export default MyProfile