import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/userSlice.js';
import { FaLinesLeaning } from "react-icons/fa6";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import idioms from "../../../assets/images/111.png";

import { RiDashboardFill } from "react-icons/ri";
import ingilizce from "../../../assets/images/englishflag.png";
import turkce from "../../../assets/images/turkishflag.png";
import AnimatedListItem from '../dictionary/Dictionary.jsx';
import TurkishDictionary from '../dictionary/TurkishDictionary.jsx';
import ProverbsAndIdioms from '../dictionary/ProverbsAndIdioms.jsx';

const theme = {
  spacing: (factor) => `${factor * 8}px`,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: 15,
    marginTop: 25, 
    minWidth: 200,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '20px 0',

    },
    '& .MuiMenuMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#6b7280', // theme.palette.text.secondary yerine sabit bir renk kullanıldı
        marginRight: theme.spacing(1.5),
        padding: '20px',
      },
      '&:active': {
        backgroundColor: 'rgba(184, 194, 204, 0.12)', // alpha fonksiyonunun çıktısı sabit ayarlandı
      },
    },
  },
}));

const Search = styled.input`
    width: 300px;
    height:50px;
    outline: none;
    background-color: #fafafa;
    padding-left: 30px;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;
    color: #384152;
    border: 2px solid #4d576a;
    &::placeholder {
      color: #384152;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
    }
`;
const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  color: #4c5565;
  display: inline;
  text-decoration: none;
`;


const NavigationBar = ({ item }) => {

  const dispatch = useDispatch(); // Initialize dispatch
  const navigate = useNavigate(); // Initialize navigate


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const [isTurkishChatbotVisible, setIsTurkihChatbotVisible] = useState(false);
  const [isProverbsChatbotVisible, setIsProverbsChatbotVisible] = useState(false);

  const toggleChatbotVisibility = () => {
    setIsChatbotVisible((prev) => !prev);
    handleClose();
  };

  const toggleTurkishChatbotVisibility = () => {
    setIsTurkihChatbotVisible((prev) => !prev);
    handleClose();
  };

  const toggleIdıomsChatbotVisibility = () => {
    setIsProverbsChatbotVisible((prev) => !prev);
    handleClose();
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    navigate('/');
  };



  return (
    <div className='flex justify-between items-center px-10 py-4'>

      <Styledh6><FaLinesLeaning style={{ width: "25px", height: "25px", color: "#384152", display: "inline", marginRight: "10px" }} />{item}</Styledh6>
      <div className='flex items-center'>
        <form action="#" method="post" style={{ display: "inline", position: "relative", marginRight: "30px" }}>
          <Search type="text" name="search" placeholder="Buradan ara" />
          <IoIosSearch style={{ width: "25px", height: "25px", color: "#384152", position: "absolute", top: "25%", right: "20px" }} />
        </form>
        <IoIosNotificationsOutline style={{ width: "35px", height: "35px", color: "#4a5467", display: "inline", marginRight: "25px", cursor: "pointer" }} />

        <button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disableElevation
          onClick={handleClick} >
          <p style={{ fontSize: "17px", color: "#4a5467", cursor: "pointer", fontFamily: "Poppins" }}> Sözlükler</p>

        </button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={toggleIdıomsChatbotVisibility} disableRipple>
            <img src={idioms} style={{ width: "27px", marginRight: "10px" }} />
            Atasözleri ve Deyimler Sözlüğü
          </MenuItem>

          <MenuItem onClick={toggleTurkishChatbotVisibility} disableRipple>
            <img src={turkce} style={{ height: "17px", height: "17px", marginRight: "10px" }} />
            Türkçe Sözlük
          </MenuItem>
          <MenuItem onClick={toggleChatbotVisibility} disableRipple>
            <img src={ingilizce} style={{ height: "17px", marginRight: "10px" }} />
            İngilizce - Türkçe Sözlük
          </MenuItem>
        </StyledMenu>
        <AnimatedListItem
          isChatbotVisible={isChatbotVisible}
          setIsChatbotVisible={setIsChatbotVisible}
        />
        <TurkishDictionary
          isTurkishChatbotVisible={isTurkishChatbotVisible}
          setIsTurkihChatbotVisible={setIsTurkihChatbotVisible}
        />
        <ProverbsAndIdioms
          isProverbsChatbotVisible={isProverbsChatbotVisible}
          setIsProverbsChatbotVisible={setIsProverbsChatbotVisible}
        />
      </div>
    </div>)
}

export default NavigationBar