import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CoktanSecmeliTest from '../manager/MultipleQuestion.jsx';
import BoslukDoldurmaTest from '../manager/FillInTheBlankQuestion.jsx';
import DogruYanlisTest from "./TrueFalseQuestion.jsx";
import styled from 'styled-components';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { useSelector } from 'react-redux';

import SurukleBirakTest from './DragAndDropQuestion.jsx';
import axios from 'axios';
import TestResults from './TestResults.jsx';
import { store } from '../../../redux/store.js';
import { logout } from '../../../redux/userSlice.js';


const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebeef5;
  width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  justify-content: center;
  height: 90vh; /* Yüksekliği daha az yapıyoruz */
  width: 50%;
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  padding: 15px;
  margin-right: 300px;
`;

const Sidebar = styled.div`
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  right: 0;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
`;

const SidebarButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h6`
  font-family: "Poppins";
  font-weight: 600;
  color: rgb(76, 74, 112);
  display: inline;
`;

const Button = styled.button`
  margin-right: 10px;
  margin-bottom:20px;
  color: rgb(76, 74, 112);
  font-family: "Varela";
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease;

  &:disabled {
    cursor: not-allowed;
  }
`;

const TimerContainer = styled.div`
  width: 80%;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
`;

const TimerBackground = styled.div`
  width: 100%;
  height: 20px;
  background-color: #6d779c;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
`;

const TimerBar = styled.div`
  height: 100%;
  width: ${props => props.timeLeft}%;
  background: linear-gradient(90deg,  #ea517c 0%,#b478f1  100%);
  transition: width 1s linear;
  border-radius: 10px;
`;

const QuestionInfo = styled.div`
  font-family: "Jost";
  color: #b4b8c5;
  margin-bottom: 25px;
  font-size: 25px;
  width: 90%;
  float: left;
  font-weight: 600;
`;

const QuestionTrackerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const QuestionCircle = styled.div`
  width: 35px;
  height: 35px;
  margin: 5px;
  background-color: ${({ status }) =>
    status === 'current' ? '#ea517c' :
      status === 'answered' ? 'rgb(76, 74, 112)' : // Cevap verilmişse mor renk
        'rgb(249, 249, 249)' // Henüz cevaplanmamışsa
  };

  border-radius: 50%;
  display: flex;
  border: 1px solid #9b9b9b;
  align-items: center;
  justify-content: center;
  color: ${({ status }) =>
    status === 'current' ? 'white' :
      status === 'answered' ? 'white' : '#505050'};
  font-family: 'Poppins';
  font-weight: 600;
  cursor: pointer;
`;

const TestManager = () => {
  const location = useLocation();
  const { lessonId, topicId, selectedTestType, unitId, testId, testTitle } = location.state || {};
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [totalDuration, setTotalDuration] = useState(1);
  const [questionStatus, setQuestionStatus] = useState([]);
  const [selectedTestId, setSelectedTestId] = useState(0);
  const [testResponse, setTestResponse] = useState("");
  const [tests, setTests] = useState("");




  const userInfo = useSelector((state) => state.user.userInfo);
  const levelId = userInfo.student.grade_id;
  const token = useSelector((state) => state.user.token);   

  // Test tipini ve ID'sini çekme
  useEffect(() => {
    const fetchTestType = async () => {
      try {
        const response = await axios.get(`https://api.robark.com.tr/api/grade/${levelId}/lesson/${lessonId}/unit/${unitId}/topic/${topicId}/tests`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log("testler", response)
        if (response.data.status) {

          setTests(response.data.tests);

        } else {
          console.error("Test verileri alınamadı.");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("status", error.response.status);
          store.dispatch(logout());
        }
        console.error("API çağrısı sırasında hata oluştu:", error);
      }
    };

    fetchTestType();
  }, [lessonId, unitId, topicId, selectedTestType]); // bağımlılıkları güncelle


  // Test sorularını çekme
  useEffect(() => {
    const fetchQuestions = async () => {
      if (testId) {
        try {
          let url = '';
          switch (selectedTestType) {
            case '2':
              url = `https://api.robark.com.tr/api/grade/${levelId}/lesson/${lessonId}/unit/${unitId}/topic/${topicId}/test/${testId}/multiple-questions`;
              break;
            case '3':
              url = `https://api.robark.com.tr/api/grade/${levelId}/lesson/${lessonId}/unit/${unitId}/topic/${topicId}/test/${testId}/fill-in-the-blanks-questions`;
              break;
            case '1':
              url = `https://api.robark.com.tr/api/grade/${levelId}/lesson/${lessonId}/unit/${unitId}/topic/${topicId}/test/${testId}/true-false-questions`;
              break;
            case '4':
              url = `https://api.robark.com.tr/api/grade/${levelId}/lesson/${lessonId}/unit/${unitId}/topic/${topicId}/test/${testId}/drag-and-drop-questions`;
              break;
            default:
              throw new Error('Geçersiz test türü.');
          }

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          if (response.data.status) {
            setQuestions(response.data.questions);
          } else {
            console.error("Sorular alınırken bir sorun oluştu.");
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log("status", error.response.status);
            store.dispatch(logout());
          }
          console.error("API çağrısı sırasında hata oluştu:", error);
        }
      }
    };

    fetchQuestions();
  }, [testId, selectedTestType, levelId, lessonId, unitId, topicId]);
  useEffect(() => {
    const durationInMillis = totalDuration * 60 * 1000;
    const endTime = Date.now() + durationInMillis;

    const timer = setInterval(() => {
      const now = Date.now();
      const remainingTime = endTime - now;

      if (remainingTime <= 0) {
        setTimeLeft(0);
        clearInterval(timer);
      } else {
        setTimeLeft(remainingTime);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [totalDuration]);

  const formatTime = (timeInMillis) => {
    const minutes = Math.floor(timeInMillis / 60000);
    const seconds = Math.floor((timeInMillis % 60000) / 1000);
    return `${minutes} dakika ${seconds} saniye kaldı`;
  };

  console.log("testiddd", testId)

  const handleAnswerChange = (questionId, answer) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));

    const newStatus = questionStatus.map((status, index) =>
      index === currentQuestionIndex ? 'answered' : status
    );
    setQuestionStatus(newStatus);
  };


  const handleSubmit = async () => {
    const answers = Object.keys(selectedAnswers).map((questionId) => ({
      question_id: Number(questionId),
      user_answer: selectedAnswers[questionId],
    }));

    const data = {
      test_id: testId,
      lesson_id: lessonId,
      topic_id: topicId,
      grade_id: levelId,
      answers,
    };

    try {
      let url = '';
      switch (selectedTestType) {
        case '2':
          url = 'https://api.robark.com.tr/api/check-multiple-questions';
          break;
        case '3':
          url = 'https://api.robark.com.tr/api/check-fill-in-the-blanks-questions';
          break;
        case '1':
          url = 'https://api.robark.com.tr/api/check-true-false-questions';
          break;
        case '4':
          url = 'https://api.robark.com.tr/api/check-drag-and-drop-questions';
          break;
        default:
          throw new Error('Geçersiz test türü.');
      }
      console.log("giden", data)

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setTestResponse(response.data);
        console.log("dönen cevap", response.data)
      } else {
        console.error("Sorular alınırken bir sorun oluştu.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("status", error.response.status);
        store.dispatch(logout());
      }
      console.error("API çağrısı sırasında hata oluştu:", error);
    }
  };


  console.log(questions)

  console.log("cevaplar", testResponse)

  const renderTestComponent = () => {
    const currentQuestion = questions[currentQuestionIndex] || {}; // Ensure currentQuestion is not undefined

    if (questions.length === 0) {
      return <p>Soru bulunamadı.</p>;
    }

    switch (selectedTestType) {
      case '2':
        return (
          <CoktanSecmeliTest
            currentQuestion={currentQuestion}
            selectedAnswers={selectedAnswers}
            handleAnswerChange={handleAnswerChange}
          />
        );
      case '3':
        return (
          <BoslukDoldurmaTest
            currentQuestion={currentQuestion}
            selectedAnswers={selectedAnswers}
            handleAnswerChange={handleAnswerChange}
          />
        );
      case '1':
        return (
          <DogruYanlisTest
            currentQuestion={currentQuestion}
            selectedAnswers={selectedAnswers}
            handleAnswerChange={handleAnswerChange}
          />
        );
      case '4':
        return (
          <SurukleBirakTest
            currentQuestion={currentQuestion}
            selectedAnswers={selectedAnswers}
            handleAnswerChange={handleAnswerChange}
          />
        );
      default:
        return <p>Test türü tanımlı değil.</p>;
    }
  };

  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  return (
    <>
      {!testResponse ? (
        <PageWrapper>
          {/* testi çöz */}
          <Container>
            <TimerContainer>
              <TimerBackground>
                <TimerBar timeLeft={(timeLeft / (totalDuration * 60 * 1000)) * 100} />
              </TimerBackground>
              <h6 style={{ color: "#dcdcdc", fontFamily: "Raleway" }}>{formatTime(timeLeft)}</h6>
            </TimerContainer>


            <QuestionInfo>
              {`Soru ${currentQuestionIndex + 1}/${questions.length}`}
            </QuestionInfo>

            {renderTestComponent()}

            <ButtonWrapper>
              <Button
                onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
                disabled={currentQuestionIndex === 0}
              >
                <FaAngleLeft style={{ width: "15px", height: "15px", display: "inline", marginBottom: "3px", marginRight: "5px" }} />
                <Title>Önceki</Title>
              </Button>
              {!isLastQuestion ? (
                <Button
                  onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
                >
                  <Title>Sonraki</Title>
                  <FaAngleRight style={{ width: "15px", height: "15px", display: "inline", marginBottom: "3px", marginLeft: "5px" }} />
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                >
                  <Title>Gönder</Title>
                </Button>
              )}
            </ButtonWrapper>
          </Container>

          <Sidebar>
            <div style={{ margin: "10px" }}>
              <h6 style={{ fontFamily: "Raleway", fontWeight: "600", color: "rgb(76, 74, 112)", display: "block", textAlign: "center" }}>
                {testTitle}
              </h6>
            </div>
            <QuestionTrackerContainer>
              {questions.length > 0 && questions.map((question, index) => (
                <QuestionCircle
                  key={question.id}
                  status={
                    currentQuestionIndex === index
                      ? 'current'
                      : selectedAnswers[question.id] !== undefined
                        ? 'answered'
                        : 'notAnswered'
                  }
                  onClick={() => setCurrentQuestionIndex(index)}
                >
                  {index + 1}
                </QuestionCircle>

              ))}
            </QuestionTrackerContainer>
            <SidebarButtonWrapper>
              <button
                style={{ backgroundColor: "rgb(76, 74, 112)", color: "white", fontFamily: "Poppins", padding: "7px 13px", borderRadius: "3px" }}
                onClick={handleSubmit}
              >
                Testi Bitir
              </button>
            </SidebarButtonWrapper>
          </Sidebar>
        </PageWrapper>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <TestResults testResponse={testResponse} selectedTestType={selectedTestType} />
        </div>
      )}
    </>
  );

};

export default TestManager;

