import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FiUser, FiLock } from 'react-icons/fi';
import { TbCircleChevronDown } from "react-icons/tb";
import { MdOutlineMailOutline } from "react-icons/md";
import { LuMailPlus } from "react-icons/lu";
import { AiOutlineNumber } from "react-icons/ai";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { fetchUserInfo, saveToken } from '../redux/userSlice';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const LoginBox = styled.div`
  padding: 5em 2.5em 4em 2.5em;
  color: #6d6d6e;
  box-shadow: none;
  margin: auto;
`;



const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 30px;
    color: #5a5a5d;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
`;

const InputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const InputField = styled.input`
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: #4c4a70;
  padding-inline: 20px 50px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #acacbb;
  border-radius: 30px;
  outline: none;

  &:focus ~ label,
  &:valid ~ label {
    position: absolute;
    top: -10px;
    left: 20px;
    font-size: 14px;
    background-color: #4c4a70;
    border-radius: 30px;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    padding: 0 10px;
  }
`;

const Label = styled.label`
  position: absolute;
  top: 15px;
  left: 20px;
  transition: 0.2s;
`;

const Icon = styled.i`
  position: absolute;
  top: 18px;
  right: 25px;
  font-size: 20px;
`;

const RememberForgot = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 15px;
`;

const InputSubmit = styled.input`
  width: 100%;
  height: 50px;
  background: #4c4a70;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #4c4a70bd;
  }
`;

const Register = styled.div`
  text-align: center;

  a {
    font-weight: 500;
    cursor: pointer;
  }
`;

const AuthContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  margin: auto; 
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const LeftPanel = styled.div`
  flex: 0.8;
  background-color: #f7f7fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const RightPanel = styled.div`
  flex: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SelectField = styled.select`
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: #4c4a70;
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #acacbb;
  border-radius: 30px;
  outline: none;
  appearance: none;
`;

const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const GradeAndStudentNoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SchoolOption = styled.div`
  margin: 10px 0;
`;

const CheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
`;

const HiddenCheckbox = styled.input`
  visibility: hidden;
  display: none;
`;

const Label2 = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    vertical-align: middle;
  }

  .path1 {
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    transition: 0.5s all;
  }

  ${HiddenCheckbox}:checked + & svg g path {
    stroke-dashoffset: 0;
  }
`;
function LoginStudent() {
  const [isRegistering, setIsRegistering] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    type: 'individual',
    isInstitutional:false,
    phone:"",
    city_id: "",
    school_id: "",
    grade_id:"",
    class_id: "", // Sınıf alanı
    kvkk: "1"
  });
  const dispatch = useDispatch();
  const role = "student";
  const [grades, setGrades] = useState([]);
  const [cities, setCities] = useState([]);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]); // Sınıf verileri için state
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange2 = () => {
    setChecked(!checked);
    setFormData(prev => ({
      ...prev,
      isInstitutional: !prev.isInstitutional,
      modelType: !checked ? 'institutional' : 'individual', // modelType'ı güncelle
    }));
  };
  


  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await fetch('https://api.robark.com.tr/api/grades');
        const data = await response.json();
        if (data.status) {
          setGrades(data.grades);
        } else {
          console.error('Sınıf düzeyleri alınamadı:', data.message);
        }
      } catch (error) {
        console.error('API isteği hatası:', error);
      }
    };

    fetchGrades();
  }, []);

  useEffect(() => {
    fetch('https://api.robark.com.tr/api/cities')
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          setCities(data.cities || []);
        }
      })
      .catch(error => console.error('Şehirler yüklenirken hata oluştu:', error));
  }, []);

  useEffect(() => {
    fetch('https://api.robark.com.tr/api/schools')
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          const schoolNames = data.schools.map(school => ({ id: school.id, name: school.name }));
          setSchools(schoolNames);
        }
      })
      .catch(error => console.error('Okullar yüklenirken hata oluştu:', error));
  }, []);

  useEffect(() => {
    // Okul değiştiğinde sınıfları yükle
    if (formData.school_id) {
      fetch(`https://api.robark.com.tr/api/school/${formData.school_id}/all-classes`)
        .then(response => response.json())
        .then(data => {
          if (data.status) {
            setClasses(data.classes || []);
          } else {
            console.error('Sınıflar alınamadı:', data.message);
          }
        })
        .catch(error => console.error('Sınıflar yüklenirken hata oluştu:', error));
    }
  }, [formData.school_id]); // formData.school değiştiğinde sınıfları yükle

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'city') {
      const selectedCity = cities.find(city => city.name === value);
      setFormData({ ...formData, [name]: value, cityId: selectedCity ? selectedCity.id : '' });
    } else if (name === 'school') {
      setFormData({ ...formData, [name]: value, school_id: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let url, payload;

      if (isRegistering) {
          url = 'https://api.robark.com.tr/api/student/register';
          payload = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            password: formData.password,
            phone: formData.phone,
            grade_id: formData.grade_id,
            class_id: formData.class_id,
            city_id: formData.city_id,
            school_id: formData.school_id
        }
      } else {
        url = 'https://api.robark.com.tr/api/student/login';
        payload = {
          email: formData.email,
          password: formData.password,
        }
      }

      const response = await axios.post(url, payload, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': '*'
        }
      });

      if (isRegistering) {
        console.log('Kayıt başarılı:', response.data);

      } else {
        console.log('Giriş başarılı:', response.data);
        if (response.data.token) {

          localStorage.setItem('token', response.data.token);
          if (response.data.token && response.data.status) {
            dispatch(saveToken(response.data.token)); 
            dispatch(fetchUserInfo(response.data.token));
                
          }
          console.log("rrrr",response.data)
          console.log('Token başarıyla kaydedildi.');

          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,            draggable: true,
            progress: undefined,
            theme: 'colored',
        });

        setTimeout(() => {
          navigate('/student');
      }, 2000); // 2000ms = 2 saniye

        } else {
          console.error('Token alınamadı veya giriş başarısız:', response.data.message);

        }

      }
    } catch (error) {
      console.error(isRegistering ? 'Kayıt başarısız:' : 'Giriş başarısız:', error);
      console.log("eerr",error)
      toast.error(error.response.data.message, {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,          
        draggable: true,
        progress: undefined,
        theme: 'colored',
    });

      console.log(formData)
    }
  };




  const handleInstitutionalChange = () => {
    setFormData(prev => ({
      ...prev,
      isInstitutional: !prev.isInstitutional,
      modelType: !prev.isInstitutional ? 'institutional' : 'individual',
    }));
  };


  const toggleForm = () => {
    setIsRegistering(!isRegistering);
  };

  return (
    <AuthContainer>
     <FormContainer>
        <LeftPanel >
          <LoginBox style={{ width: isRegistering ? "600px" : "500px", }}>
            <LoginHeader>
              <span>{isRegistering ? "Kayıt Ol" : "Giriş Yap"}</span>
            </LoginHeader>
            <form onSubmit={handleSubmit} style={{ boxShadow: "none" }}>
              {isRegistering ? (
                <>
                  <NameContainer>
                    <InputBox style={{ flex: 1, marginRight: '10px' }}>
                      <InputField
                        type="text"
                        id="name"
                        name="name"
                        value={formData.first_name}
                        onChange={handleInputChange}
                        required
                      />
                      <Label htmlFor="name">İsim</Label>
                      <Icon>
                        <FiUser style={{ color: "#8f8e9f" }} />
                      </Icon>
                    </InputBox>

                    <InputBox style={{ flex: 1 }}>
                      <InputField
                        type="text"
                        id="surname"
                        name="surname"
                        value={formData.last_name}
                        onChange={handleInputChange}
                        required
                      />
                      <Label htmlFor="surname">Soyad</Label>
                      <Icon>
                        <FiUser style={{ color: "#8f8e9f" }} />
                      </Icon>
                    </InputBox>
                  </NameContainer>
                  <GradeAndStudentNoContainer>
                    <InputBox>
                      <InputField
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                      />
                      <Label htmlFor="phoneNumber">Telefon Numarası</Label>
                      <Icon>
                        <AiOutlineNumber style={{ color: "#8f8e9f" }} />
                      </Icon>
                    </InputBox>
                    <InputBox style={{ flex: 1, marginRight: '10px' }}>
                      <SelectField
                        id="gradeLevel"
                        name="gradeLevel"
                        value={formData.grade_id}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" disabled hidden>
                          Sınıf Düzeyi
                        </option>
                        {grades.map(grade => (
                          <option key={grade.id} value={grade.level}>
                            {grade.level}. Sınıf
                          </option>
                        ))}
                      </SelectField>
                      <Icon>
                        <TbCircleChevronDown style={{ color: "#8f8e9f" }} />
                      </Icon>
                    </InputBox>
                  </GradeAndStudentNoContainer>
                  <InputBox>
                    <SelectField
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="" disabled hidden>
                        Şehir Seçin
                      </option>
                      {cities.map(city => (
                        <option key={city.id} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                    </SelectField>
                    <Icon>
                      <TbCircleChevronDown style={{ color: "#8f8e9f" }} />
                    </Icon>
                  </InputBox>
                  <InputBox>
                    <InputField
                      type="text"
                      id="user"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <Label htmlFor="user">Email</Label>
                    <Icon>
                      <LuMailPlus style={{ color: "#8f8e9f" }} />
                    </Icon>
                  </InputBox>

                  <InputBox>
                    <InputField
                      type="password"
                      id="pass"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                    <Label htmlFor="pass">Şifre</Label>
                    <Icon>
                      <FiLock style={{ color: "#8f8e9f" }} />
                    </Icon>
                  </InputBox>
                  <SchoolOption>

                  <CheckboxWrapper>
                    <HiddenCheckbox
                      type="checkbox"
                      className="check"
                      id="check1-62"
                      checked={checked}
                      onChange={handleCheckboxChange2}
                    />
                    <Label2 htmlFor="check1-62">
                      <svg width="43" height="43" viewBox="0 0 90 90">
                        <rect x="30" y="20" width="50" height="50" stroke="gray" fill="none" />
                        <g transform="translate(0,-952.36218)">
                          <path d="m 13,983 c 33,6 40,26 55,48 " stroke="gray" strokeWidth="3" className="path1" fill="none" />
                          <path d="M 75,970 C 51,981 34,1014 25,1031 " stroke="gray" strokeWidth="3" className="path1" fill="none" />
                        </g>
                      </svg>
                      <span>Okulum Robark'a kayıtlı</span>
                    </Label2>
                  </CheckboxWrapper>


                  </SchoolOption>
                  {formData.isInstitutional && (
                    <>
                      <InputBox>
                        <SelectField
                          id="school"
                          name="school"
                          value={formData.school_id}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="" disabled hidden>
                            Okul Seçin
                          </option>
                          {schools.map(school => (
                            <option key={school.id} value={school.id}>
                              {school.name}
                            </option>
                          ))}
                        </SelectField>
                        <Icon>
                          <TbCircleChevronDown style={{ color: "#8f8e9f" }} />
                        </Icon>
                      </InputBox>
                      <InputBox>
                        <SelectField
                          id="class"
                          name="class"
                          value={formData.class_id}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="" disabled hidden>
                            Sınıf Düzeyi Seçin
                          </option>
                          {classes.map(cls => (
                            <option key={cls.id} value={cls.id}>
                              {cls.class_name}
                            </option>
                          ))}
                        </SelectField>
                        <Icon>
                          <TbCircleChevronDown style={{ color: "#8f8e9f" }} />
                        </Icon>
                      </InputBox>

                    </>
                  )}
                </>
              ) : (
                <>
                  <InputBox>
                    <InputField
                      type="text"
                      id="user"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <Label htmlFor="user">Email</Label>
                    <Icon>
                      <MdOutlineMailOutline style={{ color: "#8f8e9f" }} />
                    </Icon>
                  </InputBox>
                  <InputBox>
                    <InputField
                      type="password"
                      id="pass"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                    <Label htmlFor="pass">Şifre</Label>
                    <Icon>
                      <FiLock style={{ color: "#8f8e9f" }} />
                    </Icon>
                  </InputBox>

                </>
              )}
              {!isRegistering && (
                <RememberForgot>

                  <div>
                    <a href="#" style={{ fontFamily: "Raleway" }}>Şifreni mi unuttun?</a>
                  </div>
                </RememberForgot>
              )}

              <InputBox>
                <InputSubmit type="submit" value={isRegistering ? "Kayıt Ol" : "Giriş Yap"} />
              </InputBox>
              <Register>
                <span>{isRegistering ? "Zaten hesabın var mı?" : "Hesabın yok mu?"}
                  <a onClick={toggleForm} style={{ textDecoration: "underline", color: "#4a4959", fontFamily: "Poppins" }}>
                    {isRegistering ? " Giriş yap" : " Kayıt ol"}
                  </a>
                </span>
              </Register>
            </form>
          </LoginBox>
        </LeftPanel>
        <RightPanel>
        </RightPanel>
      </FormContainer>
    </AuthContainer>
  );
}

export default LoginStudent;