import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { CiUser } from "react-icons/ci";
import { useSelector } from 'react-redux';
import { TbCircleChevronDown } from "react-icons/tb";

const Container = styled.div`
  box-shadow: none;
  background-color: rgba(111, 133, 147, 0.1);
  border-radius: 20px;
  margin-top: 20px;
  margin-left: 40px;
  padding-bottom: 120px;
  padding: 60px;
`;

const FormContainer = styled.div`
  display: flex;
  background-color: #fff;
  padding: px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  border-radius: 15px;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: 25px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 22px;
  margin-bottom: 25px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  color: #194569;
  margin-bottom: 10px;
  font-size: 18px;
`;

const Input = styled.input`
  width: 100%;
  color: #434343;
  padding: 12px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
`;

const Select = styled.select`
  width: 100%;
  color: #434343;
  padding: 12px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
`;

const ButtonSec = styled.button`
  background-color: ${props => props.active ? '#194569' : '#fff'};
  color: ${props => props.active ? '#fff' : '#194569'};
  border: ${props => props.active ? 'none' : '2px solid #194569'};
  font-size: 20px;
  height: 70px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  border-radius: 25px;
  &:hover {
    box-shadow: ${props => props.active ? 'none' : 'rgba(0, 0, 0, 0.24) 0px 3px 8px'};
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 20px;
  font-size: 16px;
`;

const Styledh4 = styled.h4`
  color: #354959;
  font-family: "Raleway";
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  font-size: 24px;
`;

const Icon = styled.i`
  position: absolute;
  top: 18px;
  right: 25px;
  font-size: 23px;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 20px;
`;


const InputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const SelectField = styled.select`
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: #4c4a70;
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #acacbb;
  border-radius: 30px;
  outline: none;
  appearance: none;
`;


const ClassEdit = () => {


  const schoolId = useSelector((state) => state.user.userInfo.staff.school_id);
  const token = localStorage.getItem('token');
  const [gradeLevelId, setGradeLevelId] = useState('');
  const [classes, setClasses] = useState([]);
  const [classId, setClassId] = useState('');
  const [gradeId, setGradeId] = useState("");
  const [branchId, setBranchId] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [grades, setGrades] = useState([]);
  const [branches, setBranches] = useState([]);



  const [data, setData] = useState({

    grade_id: gradeId,
    branch_id: branchId,
    teacher_id: teacherId,
    school_id: schoolId,
  });

  useEffect(() => {

    if (schoolId) {
      fetch(`https://api.robark.com.tr/api/school/${schoolId}/all-classes`)
        .then(response => response.json())
        .then(data => {
          if (data.status) {
            setClasses(data.classes || []);
          } else {
            console.error('Sınıflar alınamadı:', data.message);
          }
        })
        .catch(error => console.error('Sınıflar yüklenirken hata oluştu:', error));
    }
  }, [schoolId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };



  useEffect(() => {
    if (!schoolId) return;
    const fetchTeachers = async () => {
        try {
            const response = await axios.get(`https://api.robark.com.tr/api/school/${schoolId}/teachers`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTeachers(response.data.teachers || []);
            if (!response.data.teachers) console.log('Öğretmen bulunamadı.');
        } catch (err) {
            console.log(`Teachers API request failed: ${err.message}`);
        }
    };

    fetchTeachers();
}, [schoolId, token]);


const handleSubmit = async () => {
  if (!classId) {
      console.error('Sınıf seçilmedi');
      // Kullanıcıya bir sınıf seçmesi gerektiğini bildiren bir hata mesajı gösterilebilir
      return;
  }

  try {
      const response = await axios.patch(
          `https://api.robark.com.tr/api/school/${schoolId}/class/${classId}`,
          data,
          {
              withCredentials: true,
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Headers': '*'
              }
          }
      );
      console.log('API response:', response.data);
  } catch (error) {
      console.error('Error posting student data:', error);
  }
};

  useEffect(() => {
    setData(prevState => ({
      ...prevState,
      branch_id: branchId,
      teacher_id: teacherId,
    }));
  }, [branchId, teacherId]);
  
  const handleSelectChange = (e) => {
    const selectedClassId = e.target.value;
    const selectedClass = classes.find((cls) => cls.class_id.toString() === selectedClassId);

    if (selectedClass) {
      setClassId(selectedClassId);
      const selectedGradeLevelId = selectedClass.grade_level.id;
      setGradeLevelId(selectedGradeLevelId);
      setData((prevState) => ({
        ...prevState,
        grade_id: selectedGradeLevelId
      }));
      console.log("Güncellenen grade_id:", selectedGradeLevelId);
    } else {
      console.error("Seçilen sınıf bulunamadı");
    }
  };


  useEffect(() => {
    if (!token) {
      console.log('Token bulunamadı, lütfen giriş yapın.');
      return;
    }

    const fetchData = async (url, setData, dataKey) => {
      try {
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setData(response.data[dataKey]);
      } catch (err) {
        console.log(`${url} API request failed: ${err.message}`);
      }
    };

    fetchData('https://api.robark.com.tr/api/grades', setGrades, 'grades');
    fetchData('https://api.robark.com.tr/api/branches', setBranches, 'branches');
  }, [token]);



  return (
    <Container>
      <FormContainer>
        <FormField>
          <Label>Sınıf</Label>
          <InputBox>
            <SelectField id="class" name="class" value={classId} onChange={handleSelectChange} required>
              <option value="" disabled hidden>
                Değişiklik yapmak istediğiniz sınıfı seçin.
              </option>
              {classes.map(cls => (
                <option key={cls.class_id} value={cls.class_id}>
                  {cls.class_name}
                </option>
              ))}
            </SelectField>
            <Icon>
              <TbCircleChevronDown style={{ color: "#757484" }} />
            </Icon>
          </InputBox>
        </FormField>

        {classId && <>
          <FormField>
            <Label>Sınıf Düzeyi</Label>
            <InputContainer>
              <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
              <Select value={gradeId} onChange={(e) => setGradeId(parseInt(e.target.value))} required>
                <option value="">Seçiniz</option>
                {grades.map((grade) => (
                  <option key={grade.id} value={grade.id}>
                    {grade.level}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </FormField>
          <FormField>
            <Label>Sınıf Şubesi </Label>
            <InputContainer>
              <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
              <Select value={branchId} onChange={(e) => setBranchId(parseInt(e.target.value))} required>
                <option value="">Seçiniz</option>
                {branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.branch}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </FormField>
          <FormField>
            <Label>Silmek istediğiniz öğretmeni seçin.</Label>
            <InputContainer>
              <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
              <Select value={teacherId} onChange={(e) => setTeacherId(parseInt(e.target.value))} required>
                <option value="">Seçiniz</option>
                {teachers.map((teacher) => (
                  <option key={teacher.id} value={teacher.id}>
                    {teacher.name} {teacher.surname}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </FormField>
        </>
        }
        <ButtonSec onClick={handleSubmit}>Değişiklikleri Kaydet</ButtonSec>

      </FormContainer>
    </Container>
  );
};

export default ClassEdit;
