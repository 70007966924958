import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CiUser } from "react-icons/ci";
import { FaUser } from "react-icons/fa";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";
import { useSelector } from 'react-redux';
import axios from 'axios';

const Container = styled.div`
  box-shadow: none;
  background-color: #fcfcfc;
  border-radius: 20px;
  margin-top: 10px;
  margin-left: 20px;
  min-height: 100vh;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 20px;
`;

const ButtonSec = styled.button`
  background-color: ${props => props.active ? '#194569' : '#ffffff'};
  color: ${props => props.active ? '#ffffff' : '#194569'};
  border: ${props => props.active ? 'none' : '2px solid #194569'};
  font-size: 17px;
  letter-spacing: normal;
  height:60px;
  font-family: 'Poppins',sans-serif;
  cursor: pointer;
  width: 50%;
  border-radius: 20px;
  &:hover {
    box-shadow: ${props => props.active ? 'none' : 'rgba(0, 0, 0, 0.24) 0px 3px 8px;'};
  }
`;

const FormContainer = styled.div`
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;  
  border-radius: 10px;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  color: #194569;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  color: #434343;
  padding: 8px;
  border: none;
  outline: none;

`;



const UploadContainer = styled.div`
  text-align: center;
  padding: 20px;
  background-color: white;
  border: 2px dashed #ccc;
  width: 70%;
`;
const Select = styled.select`
  width: 100%;
  color: #434343;
  padding: 12px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
`;

const UploadButton = styled.input`
  margin-top: 10px;
`;

const Styledh4 = styled.h4`
color: #354959;
  font-family: "Raleway";
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  font-size: 21px;
`;
const AddTeacher = () => {

    const [teachers, setTeachers] = useState([]);
    const [teacherId, setTeacherId] = useState(0);

    const token = localStorage.getItem('token');

    const schoolId = useSelector((state) => state.user.userInfo.staff.school_id);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    };

    useEffect(() => {
        if (!schoolId) return;
        const fetchTeachers = async () => {
            try {
                const response = await axios.get(`https://api.robark.com.tr/api/school/${schoolId}/teachers`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setTeachers(response.data.teachers || []);
                if (!response.data.teachers) console.log('Öğretmen bulunamadı.');
            } catch (err) {
             
                console.log(`Teachers API request failed: ${err.message}`);
            }
        };

        fetchTeachers();
    }, [schoolId, token]);


    const handleSubmit = async () => {
      try {
          const response = await axios.delete(`https://api.robark.com.tr/api/school/${schoolId}/teacher/${teacherId}`, {
              withCredentials: true,
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Headers': '*'
              }
          });
          console.log('API response:', response.data);
      } catch (error) {
     
          console.error('Error posting teacher data:', error);

      }
  };
  

    return (
        <Container className='px-44 py-32'>


            <FormContainer>
                <FormField>
                    <Label>Silmek istediğiniz öğretmeni seçin.</Label>
                    <InputContainer>
                        <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
                        <Select value={teacherId} onChange={(e) => setTeacherId(parseInt(e.target.value))} required>
                            <option value="">Seçiniz</option>
                            {teachers.map((teacher) => (
                                <option key={teacher.id} value={teacher.id}>
                                    {teacher.name} {teacher.surname}
                                </option>
                            ))}
                        </Select>
                    </InputContainer>
                </FormField>
                <ButtonSec onClick={handleSubmit}>Sil</ButtonSec>
            </FormContainer>


        </Container>
    );
};

export default AddTeacher;
