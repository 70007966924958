import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { TfiClose } from "react-icons/tfi";
import england from "../../../assets/images/england.png"
import turkiye from "../../../assets/images/turkiye.png"
import { GoArrowSwitch } from "react-icons/go";
import { useSelector } from "react-redux";

const ChatbotContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 40px;
  height: 600px;
  background-image: linear-gradient(to top, #f9fafc 0%, #eef1f5 100%);
  z-index: 999;
  @media screen and (min-width: 640px) {
    max-width: 420px;
    right: 80px;
    top: auto;
  }

  &.chatbot--closed {
    top: auto;
    width: 100%;
  }
`;
const ChatbotHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: relative;
  padding: 0 20px;
  cursor: pointer;

  &:hover {
  }

  p {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;
const Styledh6 = styled.h6`
    font-weight: 400;
    margin-top: 5px;
    font-size: 15px;
    font-family: Poppins;
    color: #4c5565;
    text-decoration: none;
    text-align: center;
`;
const ListItem = styled.li`
  position: relative;
  list-style: none;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  &:hover {
    width: 180px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 60px;
    background: linear-gradient(45deg, ${(props) => props.$startColor}, ${(props) => props.$endColor});
    opacity: 0;
    transition: 0.5s;
  }
  &:hover::before {
    opacity: 1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    background: linear-gradient(45deg, ${(props) => props.$startColor}, ${(props) => props.$endColor});
    transition: 0.5s;
    filter: blur(15px);
    z-index: -1;
    opacity: 0;
  }
  &:hover::after {
    opacity: 0.5;
  }
`;
const Title = styled.span`
  position: absolute;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform: scale(0);
  transition: 0.5s;
  transition-delay: 0s;
  ${ListItem}:hover & {
    transform: scale(1);
    transition-delay: 0.25s;
  }
`;
const EntryField = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #ffffff;
  font-family: Poppins;
  font-weight: 300;
  border-radius: 10px;
  margin: 25px;
  font-size: 17px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-top: 1px solid #ecececdd;
  height: 15%;

  &:last-child {
  height: 40%;
  padding: 0;
  padding-left: 20px;
  }
  input {
    flex-grow: 1;
    border: none;
    font-weight: 300;
    font-family: Poppins;
    font-size: 17px;
    &:focus {
      outline: none;
    }
  }
  input::placeholder {
  color: #5a5a5a; /* İstediğiniz rengi buraya yazın */
}
  svg {
    fill: #202631;
    width: 24px;
    cursor: pointer;
  }
`;
const Lang = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: Poppins;
  font-weight: 300;
  position: relative;
  border-radius: 10px;
  font-size: 17px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  height: 17%;
  margin-right: 25px;
  margin-left: 25px;

`;
const Langs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 10px;
  &:hover {
    background-color: #f2f3f5;
  }
`;
const AnimatedListItem = ({ isChatbotVisible, setIsChatbotVisible }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userInput, setUserInput] = useState("");
  const messageEndRef = useRef(null);
  const [sourceLanguage, setSourceLanguage] = useState("İngilizce"); // Başlangıç dili İngilizce
  const [targetLanguage, setTargetLanguage] = useState("Türkçe");
  const [showSourceDropdown, setShowSourceDropdown] = useState(false);
  const [showTargetDropdown, setShowTargetDropdown] = useState(false);
  const [translations, setTranslations] = useState([]);

  const handleSourceLanguageChange = (lang) => {
    setSourceLanguage(lang);
    setShowSourceDropdown(false);
  };

  const handleTargetLanguageChange = (lang) => {
    setTargetLanguage(lang);
    setShowTargetDropdown(false);
  };

  const [messages, setMessages] = useState([
    {
      isAI: true,
      text: "Translated",
    },
  ]);
  const token = useSelector((state) => state.user.token);   
  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const handleToggleChatbot = () => {
    setIsOpen(!isOpen);
  };
  const handleCloseChatbot = () => {
    setIsChatbotVisible(false);
    setIsOpen(false);
  };
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setUserInput(newValue);
  
    if (newValue.trim()) {
      setMessages([{ isAI: false, text: newValue }]);
    } else {
      setMessages([]); 
    }
  };
  
  const handleSendMessage = async () => {
    if (!userInput.trim()) return;
    setMessages((prev) => [...prev, { isAI: false, text: userInput }]);
    try {
      const response = await axios.get(
        `https://dictionary.robark.com.tr/dictionary?word=${userInput}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      if (data.meanings && data.meanings.length > 0) {
        const meaning = data.meanings[0]; // İlk anlamı alıyoruz
        setMessages((prev) => [
          ...prev,
          { isAI: true, text: `${meaning}` },
        ]);
      }
      if (data.example_english && data.example_turkish) {
        data.example_english.forEach((exampleEnglish, index) => {
          const exampleTurkish = data.example_turkish[index] || ""; // Türkçe eşleşen varsa al
          setMessages((prev) => [
            ...prev,
            {
              isAI: true,
              text: `${exampleEnglish} (${exampleTurkish})`,
            },
          ]);
        });
      }
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        { isAI: true, text: "Kelimenin anlamı bulunamadı." },
      ]);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (userInput.trim()) {
        handleSendMessage();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [userInput]);

  return (
    <>
      {isChatbotVisible && (
        <ChatbotContainer className={isOpen ? "" : "chatbot--closed"}>
          <ChatbotHeader onClick={handleToggleChatbot}>
            <p style={{ fontFamily: "Poppins", fontSize: "19px", fontWeight: "300" }}>
              Robark Dictionary
            </p>
            <div
              style={{ position: "absolute", top: "42%", right: "8%" }}
              className="icon-close"
              viewBox="0 0 32 32"
              isOpen={isOpen}
              onClick={handleCloseChatbot}>
              <TfiClose style={{ width: "15px", height: "15px", }} />
            </div>
          </ChatbotHeader>
          <Lang>
            {/* Kaynak dil seçimi */}
            <Langs style={{ position: "relative" }} >
              <img
                src={sourceLanguage === "İngilizce" ? england : turkiye}
                style={{ width: "50px" }}
                alt={sourceLanguage}
              />
              <Styledh6>{sourceLanguage}</Styledh6>
            </Langs>

            <div>
              <GoArrowSwitch
                style={{ width: "30px", height: "30px", color: "#384152", cursor: "pointer" }}
                onClick={() => {
                  // Kaynak ve hedef dilleri değiştir
                  const temp = sourceLanguage;
                  setSourceLanguage(targetLanguage);
                  setTargetLanguage(temp);
                }}
              />
            </div>

            {/* Hedef dil seçimi */}
            <Langs style={{ position: "relative" }} >
              <img
                src={targetLanguage === "İngilizce" ? england : turkiye}
                style={{ width: "50px" }}
                alt={targetLanguage}
              />
              <Styledh6>{targetLanguage}</Styledh6>
            </Langs>
          </Lang>
          <EntryField>
            <input
              type="text"
              placeholder="Write the word "
              value={userInput}
              onChange={handleInputChange}
            />
          </EntryField>
          <EntryField>
            <div style={{ width: "100%", overflowY: "auto", maxHeight: "100%" }}>
              {messages
                .filter((message) => message.isAI)
                .map((message, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      margin: "5px 0",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px 15px",
                        borderRadius: "10px",
                        backgroundColor: "#eef1f5",
                        maxWidth: "75%",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 300,
                        whiteSpace: "pre-wrap", 
                      }}
                    >
                      {message.text}
                    </div>

                  </div>
                ))}
              <div ref={messageEndRef} />
            </div>
          </EntryField>
        </ChatbotContainer>
      )}
    </>
  );
};

export default AnimatedListItem;
