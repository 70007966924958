import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import FirstSection from '../components/FirstSection';
import SecondSection from '../components/SecondSection';
import { motion } from "framer-motion"
import ThirdSection from '../components/ThirdSection';

import styled from 'styled-components';
import bg from "../assets/images/bg.svg"
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '../redux/userSlice';

const AppContainer = styled.div`
  width: 100vw;
  background-image: url(${bg});
  background-position: center;
  background-repeat: repeat-y;
  background-size: contain; 
`;

const Dashboard = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
      console.log(token,"tokenn");
    if (token) {
      dispatch(fetchUserInfo(token));
    }
  }, [dispatch]);
  

  return (
    <div className='relative ' >
      <AppContainer>
        <Navbar/>
        <FirstSection /> 
        <SecondSection/>
        <ThirdSection/>
        </AppContainer>

    </div>
  );
}

export default Dashboard;
