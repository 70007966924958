import React, { useState } from "react";
import styled from 'styled-components';
import contentData from "../../../english/components/iframe.js"; // Iframe verilerini burada kullanıyoruz

const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  color: #4c5565;
  display: inline;
  text-decoration: none;
`;

const StyledButton = styled.button`
  margin: 10px;
  padding: 10px 20px;
  background-color: #4c5565;
  color: white;
  border: none;
  width: 100px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #384152;
  }
`;

const StyledWrapper = styled.div`
  margin-top: 10px;
  font-family: "Poppins";

  button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
  }

  button.learn-more {
    width: 12rem;
    height: auto;
  }

  button.learn-more .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem;
  }

  button.learn-more .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
  }

  button.learn-more .circle .icon.arrow {
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
  }

  button.learn-more .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.29rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    transform: rotate(45deg);
  }

  button.learn-more .button-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: #282936;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
  }

  button:hover .circle {
    width: 100%;
  }

  button:hover .circle .icon.arrow {
    background: #fff;
    transform: translate(1rem, 0);
  }

  button:hover .button-text {
    color: #fff;
    font-family: "Poppins";
  }
`;

const VocabularyComponent = ({ selectedUnit }) => {
  const [currentView, setCurrentView] = useState("menu");
  const [selectedTopic, setSelectedTopic] = useState(null);

  console.log("eeeeeeee", selectedUnit)
  const handleTopicClick = (iframe) => {
    setSelectedTopic(iframe);
    setCurrentView("iframe");
  };

  const renderAllSections = () => {
    const filteredContent = contentData.find(content => content.unit === selectedUnit.title);
    if (!filteredContent) {
      return <p>Bu ünite için içerik bulunamadı.</p>;
    }
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundColor: "transparent", width: "100%", margin: "60px" }}>
        {filteredContent.sections.map((section, sectionIndex) => (
          <div key={sectionIndex} style={{
            marginBottom: "20px", display: "flex", justifyContent: "center", flexDirection: "column", border: "1px solid #e7e7e7", borderRadius: "20px", width: "80vw", padding: "25px", backgroundColor: "#f6f6f6"
          }}>

            <Styledh6>{section.section || section.sectionTitle}</Styledh6>

            {section.iframes.map((iframe, iframeIndex) => (
              <StyledWrapper key={iframeIndex} onClick={() => handleTopicClick(iframe)}>
                <button className="learn-more">
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow" />
                  </span>
                  <span className="button-text">{iframe.title}</span>
                </button>
              </StyledWrapper>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const renderIframe = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%" }}>
        <div dangerouslySetInnerHTML={{ __html: selectedTopic.content }} />
        <StyledButton onClick={() => setCurrentView("menu")}>Geri</StyledButton>
      </div>
    );
  };

  return (
    <div style={{ width: "100vw", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "-100px" }}>
      {currentView === "menu" && renderAllSections()}
      {currentView === "iframe" && renderIframe()}
    </div>
  );
};

export default VocabularyComponent;
