import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { TfiClose } from "react-icons/tfi";
import turkiye from "../../../assets/images/2.png"
import { useSelector } from "react-redux";

const ChatbotContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  border-radius: 40px;
  height: 600px;
  background-image: linear-gradient(to top, #f9fafc 0%, #eef1f5 100%);
  z-index: 999;
  @media screen and (min-width: 640px) {
    max-width: 420px;
    right: 80px;
    top: auto;
  }

  &.chatbot--closed {
    top: auto;
    width: 100%;
  }
`;
const ChatbotHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: relative;
  padding: 0 20px;
  margin: 30px;
  margin-bottom: 50px;
  cursor: pointer;

  &:hover {
  }


`;
const Styledh6 = styled.h6`
    font-weight: 400;
    margin-top: 5px;
    font-size: 15px;
    font-family: Poppins;
    color: #4c5565;
    text-decoration: none;
    text-align: center;
`;
const ListItem = styled.li`
  position: relative;
  list-style: none;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  &:hover {
    width: 180px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 60px;
    background: linear-gradient(45deg, ${(props) => props.$startColor}, ${(props) => props.$endColor});
    opacity: 0;
    transition: 0.5s;
  }
  &:hover::before {
    opacity: 1;
  }
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    background: linear-gradient(45deg, ${(props) => props.$startColor}, ${(props) => props.$endColor});
    transition: 0.5s;
    filter: blur(15px);
    z-index: -1;
    opacity: 0;
  }
  &:hover::after {
    opacity: 0.5;
  }
`;
const Title = styled.span`
  position: absolute;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transform: scale(0);
  transition: 0.5s;
  transition-delay: 0s;
  ${ListItem}:hover & {
    transform: scale(1);
    transition-delay: 0.25s;
  }
`;
const EntryField = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #ffffff;
  font-family: Poppins;
  font-weight: 300;
  border-radius: 10px;
  margin: 25px;
  font-size: 17px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-top: 1px solid #ecececdd;
  height: 15%;

  &:last-child {
  height: 50%;
  padding: 0;
  padding-left: 20px;
  }
  input {
    flex-grow: 1;
    border: none;
    font-weight: 300;
    font-family: Poppins;
    font-size: 17px;
    &:focus {
      outline: none;
    }
  }
  input::placeholder {
  color: #5a5a5a; /* İstediğiniz rengi buraya yazın */
}
  svg {
    fill: #202631;
    width: 24px;
    cursor: pointer;
  }
`;
const Lang = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-family: Poppins;
  font-weight: 300;
  position: relative;
  border-radius: 10px;
  font-size: 17px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  height: 17%;
  margin-right: 25px;
  margin-left: 25px;

`;
const Langs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 10px;

`;
const ProverbsAndIdioms = ({ isProverbsChatbotVisible, setIsProverbsChatbotVisible }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [userInput, setUserInput] = useState("");
    const messageEndRef = useRef(null);
    const [suggestions, setSuggestions] = useState([]);
    const [details, setDetails] = useState(null);
  

    const [messages, setMessages] = useState([
        {
            isAI: true,
            text: "Anlamı",
        },
    ]);
    const token = useSelector((state) => state.user.token);   
    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    const handleToggleChatbot = () => {
        setIsOpen(!isOpen);
    };
    const handleCloseChatbot = () => {
        setIsProverbsChatbotVisible(false);
        setIsOpen(false);
    };
    const handleInputChange = async (e) => {
        const query = e.target.value;
        setUserInput(query);
        setDetails(null);
    
        if (query.length === 0) {
          setSuggestions([]);
          return;
        }
    
        try {
          const response = await fetch(`http://185.250.210.54:3002/idioms?q=${query}`, {
            headers: {
              Authorization: "Bearer zFVmgxvE3jsaoFUCHc4CCWr1kFonmvVcysstHnuv044f8c19",
              "Content-Type": "application/json",
            },
          });
    
          if (!response.ok) throw new Error("API error");
    
          const allResults = await response.json();
          const uniqueResults = Array.from(
            new Map(allResults.map((item) => [item.idiomOrProverb, item])).values()
          );
    
          setSuggestions(uniqueResults);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
          setSuggestions([]);
        }
      };
    
      const selectSuggestion = (selectedIdiom) => {
        const selectedItems = suggestions.filter(
          (item) => item.idiomOrProverb === selectedIdiom
        );
    
        if (selectedItems.length > 0) {
          setUserInput(selectedItems[0].idiomOrProverb);
          setDetails(selectedItems);
          setSuggestions([]);
        }
      };
    

    const handleSendMessage = async () => {
        if (!userInput.trim()) return;

        setMessages((prev) => [...prev, { isAI: false, text: userInput }]);

        try {
            const response = await axios.get(
                `http://185.250.210.54:3001/dictionary?word=${userInput}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const meanings = response.data.means;
            if (meanings && meanings.length > 0) {
                setMessages((prev) => [
                    ...prev,
                    { isAI: true, text: meanings }, // `text` dizisini direkt kaydediyoruz
                ]);
            } else {
                setMessages((prev) => [
                    ...prev,
                    { isAI: true, text: ["Anlam bulunamadı."] },
                ]);
            }
        } catch (error) {
            setMessages((prev) => [
                ...prev,
                { isAI: true, text: ["Kelimenin anlamı getirilemedi."] },
            ]);
        }
    };




    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (userInput.trim()) {
                handleSendMessage();
            }
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [userInput]);

    return (
        <>
            {isProverbsChatbotVisible && (
                <ChatbotContainer className={isOpen ? "" : "chatbot--closed"}>
                    <ChatbotHeader onClick={handleToggleChatbot}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" ,justifyContent:"center",gap:"15px"}}>
                            <img
                                src={turkiye}
                                style={{ width: "60px" }}
                                alt={"Türkçe"}
                            />
                            <p style={{ fontFamily: "Poppins", fontSize: "19px", fontWeight: "300",color:"#4e4e53" }}>
                                Atasözleri Ve Deyimler Sözlüğü 
                            </p>
                        </div>
                        <div
                            style={{ position: "absolute", top: "10%", right: "3%" }}
                            className="icon-close"
                            viewBox="0 0 32 32"
                            isOpen={isOpen}
                            onClick={handleCloseChatbot}>
                            <TfiClose style={{ width: "15px", height: "15px", }} />
                        </div>
                    </ChatbotHeader>
                    
                    <EntryField>
                        <input
                            type="text"
                            placeholder="Kelime girin"
                            value={userInput}
                            onChange={handleInputChange}
                            />
                    </EntryField>
                    <EntryField>
                    <div id="suggestions" style={{  overflowY: "auto", marginBottom: "10px" }}>
            {suggestions.map((item, index) => (
              <div
                key={index}
                className="suggestion"
                onClick={() => selectSuggestion(item.idiomOrProverb)}
                style={{
                  padding: "10px",
                  borderBottom: "1px solid #eee",
                  cursor: "pointer",
                  backgroundColor: "white",
                  transition: "background-color 0.2s ease",
                }}
              >
                {item.idiomOrProverb}
              </div>
            ))}
          </div>

          {details && (
            <>
              {details.map((item, index) => (
                <div key={index} >
                  <div style={{ fontWeight: "500", color: "#f75446" }}>Deyim/Atasözü:</div>
                  <div style={{fontSize:"15px"}}>{item.idiomOrProverb}</div>
                  <div style={{ fontWeight: "500", color: "#f75446", marginTop: "10px" }}>Anlam:</div>
                  <div  style={{fontSize:"15px"}} >{item.meaning}</div>
                  <div style={{ fontWeight: "500", color: "#f75446", marginTop: "10px" }}>Örnekler:</div>
                  <div  style={{fontSize:"15px"}}>{item.examples}</div>
                </div>
              ))}
            </>
          )}

                    </EntryField>

                </ChatbotContainer>
            )}
        </>
    );
};

export default ProverbsAndIdioms;
