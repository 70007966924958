import React, { useState } from 'react';
import styled from 'styled-components';
import { CiUser } from "react-icons/ci";
import { FaUser } from "react-icons/fa";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";
import { useSelector } from 'react-redux';
import axios from 'axios';

const Container = styled.div`
  box-shadow: none;
  background-color: #fcfcfc;
  border-radius: 20px;
  margin-top: 10px;
  margin-left: 20px;
  min-height: 100vh;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 20px;
`;

const ButtonSec = styled.button`
  background-color: ${props => props.active ? '#194569' : '#ffffff'};
  color: ${props => props.active ? '#ffffff' : '#194569'};
  border: ${props => props.active ? 'none' : '2px solid #194569'};
  font-size: 17px;
  letter-spacing: normal;
  height:60px;
  font-family: 'Poppins',sans-serif;
  cursor: pointer;
  width: 50%;
  border-radius: 20px;
  &:hover {
    box-shadow: ${props => props.active ? 'none' : 'rgba(0, 0, 0, 0.24) 0px 3px 8px;'};
  }
`;

const FormContainer = styled.div`
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;  
  border-radius: 10px;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  color: #194569;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  color: #434343;
  padding: 8px;
  border: none;
  outline: none;

`;



const UploadContainer = styled.div`
  text-align: center;
  padding: 20px;
  background-color: white;
  border: 2px dashed #ccc;
  width: 70%;
`;

const UploadButton = styled.input`
  margin-top: 10px;
`;

const Styledh4 = styled.h4`
color: #354959;
  font-family: "Raleway";
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  font-size: 21px;
`;
const AddTeacher = () => {
    const [isBulkUpload, setIsBulkUpload] = useState(false);
    const [teacherData, setTeacherData] = useState({
        name: '',
        surname: '',
        phone: '',
        email: '',
        password: '',
        
    });
    const token = localStorage.getItem('token');

    const schoolId = useSelector((state) => state.user.userInfo.staff.school_id);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTeacherData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(
                `https://api.robark.com.tr/api/school/${schoolId}/teacher`,
                teacherData,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Headers': '*'
                    }
                }
            );
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error posting teacher data:', error);
    
        }
    };
    

    const toggleMode = () => {
        setIsBulkUpload(!isBulkUpload);
    };

    return (
        <Container className='px-44 py-32'>
            <ButtonGroup>
                <ButtonSec active={!isBulkUpload} onClick={toggleMode}>Öğretmen Ekleme <FaUser style={{ width: "15px", marginLeft: "10px", display: "inline" }} />
                </ButtonSec>
                <ButtonSec active={isBulkUpload} onClick={toggleMode}>
                    Toplu Öğretmen Ekleme <FaUserFriends style={{ width: "20px", marginLeft: "10px", display: "inline" }} />
                </ButtonSec>
            </ButtonGroup>

            {!isBulkUpload ? (
                <FormContainer>
                    <Styledh4>Eklemek istediğiniz öğretmenin bilgilerini girin.</Styledh4>
                    <FormField>
                        <Label>Adı</Label>
                        <InputContainer>
                            <CiUser className="focus-input100" style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
                            <Input
                                type="text"
                                name="name"
                                value={teacherData.name}
                                onChange={handleInputChange}
                                placeholder="Ayşe"
                            />
                        </InputContainer>
                    </FormField>
                    <FormField>
                        <Label>Soyadı</Label>
                        <InputContainer>
                            <CiUser className="focus-input100" style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
                            <Input
                                type="text"
                                name="surname"
                                value={teacherData.surname}
                                onChange={handleInputChange}
                                placeholder="Yılmaz"
                            />
                        </InputContainer>
                    </FormField>
                    <FormField>
                        <Label>Telefon Numarası</Label>
                        <InputContainer>
                            <CiUser className="focus-input100" style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
                            <Input
                                type="text"
                                name="phone"
                                value={teacherData.phone}
                                onChange={handleInputChange}
                                placeholder="0555 555 55 55"
                            />
                        </InputContainer>
                    </FormField>
                    <FormField>
                        <Label>Mail Adresi</Label>
                        <InputContainer>
                            <CiUser className="focus-input100" style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
                            <Input
                                type="email"
                                name="email"
                                value={teacherData.email}
                                onChange={handleInputChange}
                                placeholder="abc@gmail.com"
                            />
                        </InputContainer>
                    </FormField>
                    <FormField>
                        <Label>Şifre</Label>
                        <InputContainer>
                            <CiUser className="focus-input100" style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
                            <Input
                                type="password"
                                name="password"
                                value={teacherData.password}
                                onChange={handleInputChange}
                                placeholder=" ......."
                            />
                        </InputContainer>
                    </FormField>

                    <ButtonSec onClick={handleSubmit}>Ekle</ButtonSec>
                </FormContainer>
            ) : (
                <div className='w-100' style={{ display: "grid", placeItems: "center", }}>
                    <UploadContainer>
                        <div>
                            <IoCloudUploadOutline className='mx-auto' style={{ width: "80px", height: "70px", color: "#133049" }} />
                        </div>
                        <input
                            type="file"
                            name='gorseller'
                            multiple
                            style={{ display: "none" }}
                            id="fileInput"
                            accept="file/*"
                        />
                        <label htmlFor="fileInput" className="pickfoto">
                            <h6 style={{ textAlign: "center", color: "#16202e", fontFamily: "Raleway", cursor: "pointer", fontSize: "14px", marginTop: "5px" }}>
                                Dosya seç
                            </h6>
                        </label>
                        <h5>Öğretmenlerinizi toplu olarak yüklemek için öğretmen listesi içeren dosyanızı seçin.</h5>

                        <button style={{ borderRadius: "20px", width: "min-content", backgroundColor: "#133049", color: "white" }} role="button"> Yükle</button>
                    </UploadContainer>
                </div>
            )}
        </Container>
    );
};

export default AddTeacher;
