const contentData = [{
  "unit": "Unit 1", // Buraya ekledik
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [
        {
          "title": "Words 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/b_bCuN/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/_GCv8c/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 3",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/z0vgGM/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 4",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/4dLqP-/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 5",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/JPD49-/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 6",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/aLnU8p/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        }
      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/tkWygi/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/hc7ml4/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        }
      ]
    }
  ]
},
{
  "unit": "Unit 2",

  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [
        {
          "title": "Words 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/KX7KPb/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/O1xJzB/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 3",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/MH2HQR/embed' width='1200' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>>"
        },

        {
          "title": "Words 4",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/ajHWRX/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },

      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/d6yZep/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/58x_k7/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
      ]
    }
  ]

},
{
  "unit": "Unit 3",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [
        {
          "title": "Words 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/_XUYy7/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/w-xLhi/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 3",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/AEXmH1/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 4",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/h1rqCu/embed' width='1920' height=320' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 5",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/G7C7ry/embed' width='1200' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/qzSptI/embed' width='850' height='850' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/EXz0XB/embed' width='1920' height='1080' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Grammer 3",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/ezbsBV/embed' width='850' height='920' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
      ]
    }
  ]

},
{
  "unit": "Unit 4",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [
        {
          "title": "Words 1",
          "content": "<iframe src='https://quizizz.com/join?gc=85441031'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://quizizz.com/join?gc=76946925'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },

        {
          "title": "Words 3",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6728a3a02192cb3008ffd4b1'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6728c5b049afb9a7294602b1'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6728d60869753d7dced59536'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    }
  ]
},
{
  "unit": "Unit 5",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [

        {
          "title": "Words 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6729bdcfe9c2256e6e1051df'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6729ca6d8cbddd2bdaf56e54'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Words 3",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6729d42e70133e481758195d'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6729fe632551c93189f0e28f'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },

        {
          "title": "Grammer 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6729d9eb931caa37475f8a40'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Grammer 3",
          "content": "<iframe src='https://quizizz.com/embed/quiz/672a05bb41f9b15bb88f43c2'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    }
  ]
},
{
  "unit": "Unit 6",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [

        {
          "title": "Words 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/672b0f5b6fd666265d6c7456'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/672b17de2e733f499b358c06'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },

      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/672b577b62c05f54dff40aae'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/672c7f05c9aaf477ba99f865'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },

  
      ]
    }
  ]

},
{
  "unit": "Unit 7",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [

        {
          "title": "Words 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/672cbbc4cdf99a2e638a00ec'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/672cc0c5813e38f5929e2cfb'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },

      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6731b18d22c75d7251e4bb43'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6734480270e4d164d716c9db'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Grammer 3",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6735a682c7651d5bbbeeed4e'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    }
  ]

},
{
  "unit": "Unit 8",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [

        {
          "title": "Words 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6736ee2a5ecf2fd848b331b4'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6736f4ed4958a0bcf873f6ef'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },

      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/673d89167c2b60ed231317a9'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/673de2a0ed230d3766fe64aa'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    }
  ]

},{
  "unit": "Unit 9",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [

        {
          "title": "Words 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/673ee83c80a7c349516cb4a9'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/673eed8eb3c2843a6fd18476'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },

      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/67406965b657ad7bd316e645'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/67406f40b3abb25990b32a90'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    }
  ]

},{
  "unit": "Unit 10",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [

        {
          "title": "Words 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/674419d8693a971680244bf2'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/67441f26db80b541f9072410'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/67445b20bdbfac6bc6278810'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6744641022e76f95edd1aebc'  width='1920' height='1080' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    }
  ]

}







]

export default contentData;